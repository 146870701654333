<template>
  <v-container v-bind:class="{ 'pa-0': $vuetify.breakpoint.smAndDown }">

    <v-card class=" overflow-hidden" elevation="1" v-bind:class="{ 'rounded-lg': !$vuetify.breakpoint.smAndDown }">
      <v-card-title>
        <v-icon left color="primary">mdi-store</v-icon>
        Inventario & Tienda

        <v-spacer />

        <!-- Add search field -->
        <v-text-field v-show="!$vuetify.breakpoint.smAndDown" rounded filled class="mr-4 rounded-lg" v-model="search"
          dense append-icon="mdi-magnify" label="Buscar" single-line hide-details></v-text-field>

        <v-btn v-show="!$vuetify.breakpoint.smAndDown" color="primary" class="rounded-lg" @click="openEditDialog">
          <v-icon left>mdi-plus</v-icon>
          Agregar
        </v-btn>
      </v-card-title>
      <v-divider v-show="$vuetify.breakpoint.smAndDown" />
      <v-card-title v-show="$vuetify.breakpoint.smAndDown">
        <v-text-field filled rounded class="rounded-lg mr-4" v-model="search" dense append-icon="mdi-magnify"
          style="width: 180px;" label="Buscar" single-line hide-details></v-text-field>
        <v-btn color="primary" class=" rounded-lg" @click="openEditDialog">
          <v-icon left>mdi-plus</v-icon>
          Agregar
        </v-btn>
      </v-card-title>

      <v-divider />
      <v-card-text class="pa-0">

        <v-tabs v-model="tab" :show-arrows="false">
          <v-tab>
            <v-icon left>mdi-package-variant</v-icon>
            Inventario
          </v-tab>
          <v-tab>
            <v-icon left>mdi-store</v-icon>
            Gestión Tienda
          </v-tab>
          <v-tab>
            <v-icon left>mdi-chart-line</v-icon>
            Gráfico de Stock
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <!-- Inventory Tab -->
          <v-tab-item>
            <v-divider />
            <v-card class="pa-0">
              <v-data-table :headers="stockHeaders" :items="inventoryItems" :search="search" class="elevation-0"
                :items-per-page="10" :loading="loading.items" :no-data-text="'No se encontraron items'"
                :no-results-text="'No se encontraron coincidencias'">
                <template v-slot:[`item.image`]="{ item }">
                  <v-img class="my-1" :src="item.image" max-width="50" max-height="50" contain></v-img>
                </template>

                <template v-slot:[`item.stock`]="{ item }">
                  <v-chip :color="item.stock > 0 ? '' : 'error'">
                    {{ item.stock == 0 ? 'SIN STOCK' : item.stock }}
                  </v-chip>
                </template>

                <template v-slot:[`item.available`]="{ item }">
                  <v-chip :color="item.visibleAtStore ? 'success' : 'grey'">
                    {{ item.visibleAtStore ? 'Tienda' : 'Inventario' }}
                  </v-chip>
                </template>


                <template v-slot:[`item.price`]="{ item }">
                  <template v-if="item.price">
                    ${{ item.price }}
                  </template>
                </template>

                <template v-slot:[`item.actions`]="{ item }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon @click="openEditDialog(item)" v-bind="attrs" v-on="on">
                        <v-icon>
                          mdi-pencil
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Editar detalles</span>
                  </v-tooltip>

                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon @click="openStockDialog(item)" v-bind="attrs" v-on="on">
                        <v-icon>
                          mdi-package-variant
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Gestionar stock</span>
                  </v-tooltip>

                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon @click="deleteItem(item.id)" v-bind="attrs" v-on="on">
                        <v-icon>
                          mdi-delete
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Eliminar item</span>
                  </v-tooltip>
                </template>
              </v-data-table>

              <!-- Edit/Create Dialog -->
              <v-dialog v-model="editDialog" max-width="500px" :fullscreen="$vuetify.breakpoint.smAndDown">
                <v-card>
                  <v-card-title>
                    <v-btn icon class="mr-4" @click="closeEditDialog">
                      <v-icon>mdi-chevron-left</v-icon>
                    </v-btn>
                    {{ dialogTitle }}
                  </v-card-title>

                  <v-divider />
                  <v-card-text class="px-10 pt-4">
                    <v-form ref="editForm" v-model="editValid">
                      <v-text-field prepend-icon="mdi-tag" v-model="editedItem.name"
                        :rules="[v => !!v || 'El nombre es requerido']" label="Nombre del Item" required></v-text-field>

                      <v-combobox prepend-icon="mdi-shape" v-model="editedItem.category" :items="categories"
                        label="Categoría" :search-input.sync="search2"
                        :rules="[v => !!v || 'La categoría es requerida']" required>
                        <template v-slot:no-data>
                          <v-list-item>
                            <v-list-item-content>
                              <v-list-item-title>
                                No hay resultado para "<strong>{{ search2 }}</strong>".
                                Presione <kbd>enter</kbd> para agregar.
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                      </v-combobox>

                      <v-file-input prepend-icon="mdi-image" v-model="editedItem.image" label="Imagen del Item"
                        accept="image/*"
                        :placeholder="editedItem.image ? 'Cambiar imagen' : 'Seleccionar imagen'"></v-file-input>

                      <v-checkbox v-model="editedItem.visibleAtStore" label="Visible en tienda"
                        hint="Si está marcado, este item será visible en la vista de Punto de Venta"
                        persistent-hint></v-checkbox>

                      <v-text-field prepend-icon="mdi-currency-usd" class="mt-4" v-if="editedItem.visibleAtStore"
                        v-model="editedItem.price" :rules="[
                          v => !!v || 'El precio es requerido',
                          v => v > 0 || 'El precio debe ser positivo'
                        ]" label="Precio de Venta" type="number" required></v-text-field>
                    </v-form>
                  </v-card-text>
                  <v-divider v-show="!$vuetify.breakpoint.smAndDown" />
                  <v-card-actions v-show="!$vuetify.breakpoint.smAndDown">
                    <v-spacer></v-spacer>
                    <v-btn text @click="closeEditDialog">Cancelar</v-btn>
                    <v-btn :disabled="!editValid" :loading="loading.save" color="primary" @click="saveItem">
                      Confirmar
                    </v-btn>
                  </v-card-actions>
                  <v-card-actions v-show="$vuetify.breakpoint.smAndDown">

                    <v-btn x-large :disabled="!editValid" :loading="loading.save" color="primary" @click="saveItem"
                      block>
                      Confirmar
                    </v-btn>
                  </v-card-actions>
                  <v-card-actions v-show="$vuetify.breakpoint.smAndDown">
                    <v-btn large @click="closeEditDialog" block>Cancelar</v-btn>

                  </v-card-actions>

                </v-card>
              </v-dialog>

              <!-- Add Stock Management Dialog -->
              <v-dialog v-model="stockDialog" max-width="500px" :fullscreen="$vuetify.breakpoint.smAndDown">
                <v-card>
                  <v-card-title>
                    <v-btn icon class="mr-4" @click="closeStockDialog">
                      <v-icon>mdi-chevron-left</v-icon>
                    </v-btn>
                    Gestionar Stock - {{ selectedItem ? selectedItem.name : '' }}
                  </v-card-title>
                  <v-divider />
                  <v-card-text class="px-10 pt-4">
                    <v-form ref="stockForm" v-model="stockValid">
                      <v-select v-model="stockAdjustment.type" :items="[
                        { text: 'Agregar', value: 'add' },
                        { text: 'Quitar', value: 'remove' }
                      ]" label="Tipo de Ajuste" required></v-select>

                      <v-text-field v-model="stockAdjustment.quantity" :rules="[
                        v => !!v || 'La cantidad es requerida',
                        v => Number.isInteger(+v) || 'La cantidad debe ser un número entero',
                        v => +v > 0 || 'La cantidad debe ser positiva'
                      ]" label="Cantidad" type="number" required></v-text-field>

                      <v-text-field v-if="stockAdjustment.type === 'add'" v-model="stockAdjustment.costPrice" :rules="[

                        v => +v >= 0 || 'El precio de costo no puede ser negativo'
                      ]" label="Precio de Costo" type="number" required></v-text-field>

                      <v-text-field v-model="stockAdjustment.reason" label="Motivo"></v-text-field>
                    </v-form>
                  </v-card-text>
                  <v-divider />
                  <v-card-actions v-if="!$vuetify.breakpoint.smAndDown">
                    <v-spacer></v-spacer>
                    <v-btn text @click="closeStockDialog">Cancelar</v-btn>
                    <v-btn :disabled="!stockValid" :loading="loading.stock" color="primary"
                      @click="saveStockAdjustment">
                      Confirmar
                    </v-btn>
                  </v-card-actions>
                  <v-card-actions v-if="$vuetify.breakpoint.smAndDown">
                    <v-btn x-large :disabled="!stockValid" :loading="loading.stock" color="primary"
                      @click="saveStockAdjustment" block>
                      Confirmar
                    </v-btn>
                  </v-card-actions>
                  <v-card-actions v-if="$vuetify.breakpoint.smAndDown">
                    <v-btn large @click="closeStockDialog" block>Cancelar</v-btn>
                  </v-card-actions>



                </v-card>
              </v-dialog>

            </v-card>
          </v-tab-item>

          <!-- Store Management Tab -->
          <v-tab-item>
            <v-divider />
            <v-card class="pa-0">
              <v-data-table :headers="stockHeaders" :items="storeItems" :search="search" class="elevation-0"
                :items-per-page="10" :loading="loading.items" :no-data-text="'No se encontraron items'"
                :no-results-text="'No se encontraron coincidencias'">
                <template v-slot:[`item.image`]="{ item }">
                  <v-img class="my-1" :src="item.image" max-width="50" max-height="50" contain></v-img>
                </template>

                <template v-slot:[`item.stock`]="{ item }">
                  <v-chip :color="item.stock > 0 ? '' : 'error'">
                    {{ item.stock == 0 ? 'SIN STOCK' : item.stock }}
                  </v-chip>
                </template>

                <template v-slot:[`item.available`]="{ item }">
                  <v-chip :color="item.visibleAtStore ? 'success' : 'grey'">
                    {{ item.visibleAtStore ? 'Tienda' : 'Inventario' }}
                  </v-chip>
                </template>


                <template v-slot:[`item.price`]="{ item }">
                  <template v-if="item.price">
                    ${{ item.price }}
                  </template>
                </template>

                <template v-slot:[`item.actions`]="{ item }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon @click="openEditDialog(item)" v-bind="attrs" v-on="on">
                        <v-icon>
                          mdi-pencil
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Editar detalles</span>
                  </v-tooltip>

                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon @click="openStockDialog(item)" v-bind="attrs" v-on="on">
                        <v-icon>
                          mdi-package-variant
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Gestionar stock</span>
                  </v-tooltip>

                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon @click="deleteItem(item.id)" v-bind="attrs" v-on="on">
                        <v-icon>
                          mdi-delete
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Eliminar item</span>
                  </v-tooltip>
                </template>
              </v-data-table>

              <!-- Edit/Create Dialog -->
              <v-dialog v-model="editDialog" max-width="500px" :fullscreen="$vuetify.breakpoint.smAndDown">
                <v-card>
                  <v-card-title>
                    <v-btn icon class="mr-4" @click="closeEditDialog">
                      <v-icon>mdi-chevron-left</v-icon>
                    </v-btn>
                    {{ dialogTitle }}
                  </v-card-title>

                  <v-divider />
                  <v-card-text class="px-10 pt-4">
                    <v-form ref="editForm" v-model="editValid">
                      <v-text-field prepend-icon="mdi-tag" v-model="editedItem.name"
                        :rules="[v => !!v || 'El nombre es requerido']" label="Nombre del Item" required></v-text-field>

                      <v-combobox prepend-icon="mdi-shape" v-model="editedItem.category" :items="categories"
                        label="Categoría" :search-input.sync="search2"
                        :rules="[v => !!v || 'La categoría es requerida']" required>
                        <template v-slot:no-data>
                          <v-list-item>
                            <v-list-item-content>
                              <v-list-item-title>
                                No hay resultado para "<strong>{{ search2 }}</strong>".
                                Presione <kbd>enter</kbd> para agregar.
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                      </v-combobox>

                      <v-file-input prepend-icon="mdi-image" v-model="editedItem.image" label="Imagen del Item"
                        accept="image/*"
                        :placeholder="editedItem.image ? 'Cambiar imagen' : 'Seleccionar imagen'"></v-file-input>

                      <v-checkbox v-model="editedItem.visibleAtStore" label="Visible en tienda"
                        hint="Si está marcado, este item será visible en la vista de Punto de Venta"
                        persistent-hint></v-checkbox>

                      <v-text-field prepend-icon="mdi-currency-usd" class="mt-4" v-if="editedItem.visibleAtStore"
                        v-model="editedItem.price" :rules="[
                          v => !!v || 'El precio es requerido',
                          v => v > 0 || 'El precio debe ser positivo'
                        ]" label="Precio de Venta" type="number" required></v-text-field>
                    </v-form>
                  </v-card-text>
                  <v-divider v-show="!$vuetify.breakpoint.smAndDown" />
                  <v-card-actions v-show="!$vuetify.breakpoint.smAndDown">
                    <v-spacer></v-spacer>
                    <v-btn text @click="closeEditDialog">Cancelar</v-btn>
                    <v-btn :disabled="!editValid" :loading="loading.save" color="primary" @click="saveItem">
                      Confirmar
                    </v-btn>
                  </v-card-actions>
                  <v-card-actions v-show="$vuetify.breakpoint.smAndDown">

                    <v-btn x-large :disabled="!editValid" :loading="loading.save" color="primary" @click="saveItem"
                      block>
                      Confirmar
                    </v-btn>
                  </v-card-actions>
                  <v-card-actions v-show="$vuetify.breakpoint.smAndDown">
                    <v-btn large @click="closeEditDialog" block>Cancelar</v-btn>

                  </v-card-actions>

                </v-card>
              </v-dialog>

              <!-- Add Stock Management Dialog -->
              <v-dialog v-model="stockDialog" max-width="500px" :fullscreen="$vuetify.breakpoint.smAndDown">
                <v-card>
                  <v-card-title>
                    <v-btn icon class="mr-4" @click="closeStockDialog">
                      <v-icon>mdi-chevron-left</v-icon>
                    </v-btn>
                    Gestionar Stock - {{ selectedItem ? selectedItem.name : '' }}
                  </v-card-title>
                  <v-divider />
                  <v-card-text class="px-10 pt-4">
                    <v-form ref="stockForm" v-model="stockValid">
                      <v-select v-model="stockAdjustment.type" :items="[
                        { text: 'Agregar', value: 'add' },
                        { text: 'Quitar', value: 'remove' }
                      ]" label="Tipo de Ajuste" required></v-select>

                      <v-text-field v-model="stockAdjustment.quantity" :rules="[
                        v => !!v || 'La cantidad es requerida',
                        v => Number.isInteger(+v) || 'La cantidad debe ser un número entero',
                        v => +v > 0 || 'La cantidad debe ser positiva'
                      ]" label="Cantidad" type="number" required></v-text-field>

                      <v-text-field prefix="$" v-if="stockAdjustment.type === 'add'" v-model="stockAdjustment.costPrice"
                        :rules="[

                          v => +v >= 0 || 'El precio de costo no puede ser negativo'
                        ]" label="Costo lote total" type="number" required></v-text-field>

                      <v-text-field v-model="stockAdjustment.reason" label="Motivo"></v-text-field>
                    </v-form>
                  </v-card-text>
                  <v-divider />
                  <v-card-actions v-if="!$vuetify.breakpoint.smAndDown">
                    <v-spacer></v-spacer>
                    <v-btn text @click="closeStockDialog">Cancelar</v-btn>
                    <v-btn :disabled="!stockValid" :loading="loading.stock" color="primary"
                      @click="saveStockAdjustment">
                      Confirmar
                    </v-btn>
                  </v-card-actions>
                  <v-card-actions v-if="$vuetify.breakpoint.smAndDown">
                    <v-btn x-large :disabled="!stockValid" :loading="loading.stock" color="primary"
                      @click="saveStockAdjustment" block>
                      Confirmar
                    </v-btn>
                  </v-card-actions>
                  <v-card-actions v-if="$vuetify.breakpoint.smAndDown">
                    <v-btn large @click="closeStockDialog" block>Cancelar</v-btn>
                  </v-card-actions>



                </v-card>
              </v-dialog>

            </v-card>
          </v-tab-item>

          <!-- Chart Tab -->
          <v-tab-item>
            <v-divider />
            <v-card class="pa-4">
              <!-- Improved item selector layout -->
              <v-row align="center" justify="center">
                <v-col cols="12" sm="8" md="6">
                  <v-select v-model="selectedItem" :items="items" item-text="name"
                    label="Seleccionar Item para Ver Análisis" return-object outlined hide-details="auto"
                    class="mb-6"></v-select>
                </v-col>
              </v-row>

              <!-- Message when no item is selected -->
              <v-row v-if="!selectedItem" justify="center" class="mt-8">
                <v-col cols="12" class="text-center">
                  <v-icon size="64" color="grey lighten-1">mdi-chart-line</v-icon>
                  <div class="text-h6 grey--text mt-2 mb-10">Seleccione un item para ver sus análisis</div>
                </v-col>
              </v-row>

              <!-- Charts container -->
              <div v-if="selectedItem">
                <v-row>
                  <v-col cols="12" md="6">
                    <v-card class="pa-4 mb-4">
                      <v-card-title class="text-h6">Historial de Stock</v-card-title>
                      <v-skeleton-loader v-if="loading.charts" type="chart" class="mt-4"></v-skeleton-loader>
                      <apexchart v-else type="line" height="350" :options="stockChartOptions" :series="stockChartData">
                      </apexchart>
                    </v-card>
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-card class="pa-4">
                      <v-card-title class="text-h6">Análisis de Ingresos</v-card-title>
                      <v-skeleton-loader v-if="loading.charts" type="chart" class="mt-4"></v-skeleton-loader>
                      <apexchart v-else type="line" height="350" :options="earningsChartOptions"
                        :series="earningsChartData">
                      </apexchart>
                    </v-card>
                  </v-col>
                </v-row>

                <!-- New costs chart row -->
                <v-row class="mt-4">
                  <v-col cols="12" md="6">
                    <v-card class="pa-4">
                      <v-card-title class="text-h6">Costos Acumulados</v-card-title>
                      <v-skeleton-loader v-if="loading.charts" type="chart" class="mt-4"></v-skeleton-loader>
                      <apexchart v-else type="line" height="350" :options="costsChartOptions" :series="costsChartData">
                      </apexchart>
                    </v-card>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-card>
                      <v-card-title class="text-h6">Historial de Movimientos de Stock</v-card-title>
                      <v-data-table :headers="stockMovementsHeaders" :items="stockMovements"
                        :loading="loading.stockMovements" :items-per-page="5" class="elevation-0">
                        <template v-slot:[`item.date`]="{ item }">
                          {{ new Date(item.date).toLocaleString() }}
                        </template>
                        <template v-slot:[`item.type`]="{ item }">
                          <v-chip :color="item.type === 'add' ? 'success' : 'error'" small>
                            {{ item.type === 'add' ? 'Agregar' : 'Quitar' }}
                          </v-chip>
                        </template>
                        <template v-slot:[`item.change`]="{ item }">
                          {{ Math.abs(item.change) }}
                        </template>
                        <template v-slot:[`item.costPrice`]="{ item }">
                          {{ item.costPrice ? `$${item.costPrice}` : '-' }}
                        </template>
                        <template v-slot:[`item.reason`]="{ item }">
                          {{ item.reason === 'sale' ? 'Venta' : item.reason }}
                        </template>
                        <template v-slot:[`item.actions`]="{ item }">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn icon @click="openStockMovementEditDialog(item)" v-bind="attrs" v-on="on">
                                <v-icon>mdi-pencil</v-icon>
                              </v-btn>
                            </template>
                            <span>Editar movimiento</span>
                          </v-tooltip>
                        </template>
                      </v-data-table>
                    </v-card>
                  </v-col>
                </v-row>

                <!-- Summary cards -->
                <v-row class="mt-4">
                  <v-col cols="12" sm="6" md="3">
                    <v-card>
                      <v-card-text class="text-center">
                        <div class="text-h6 grey--text text--darken-1">Stock Actual</div>
                        <div class="text-h4 mt-2">{{ selectedItem.stock || 0 }}</div>
                      </v-card-text>
                    </v-card>
                  </v-col>

                  <v-col cols="12" sm="6" md="3">
                    <v-card>
                      <v-card-text class="text-center">
                        <div class="text-h6 grey--text text--darken-1">Precio Unitario</div>
                        <div class="text-h4 mt-2">${{ selectedItem.price || 0 }}</div>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </div>
            </v-card>

            <!-- Add Stock Movement Edit Dialog -->
            <v-dialog v-model="stockMovementEditDialog" max-width="500px" :fullscreen="$vuetify.breakpoint.smAndDown">
              <v-card>
                <v-card-title>
                  <v-btn icon class="mr-4" @click="closeStockMovementEditDialog">
                    <v-icon>mdi-chevron-left</v-icon>
                  </v-btn>
                  Editar Movimiento de Stock
                </v-card-title>
                <v-divider />
                <v-card-text class="px-10 pt-4">
                  <v-form ref="stockMovementEditForm" v-model="stockMovementEditValid">
                    <v-text-field prefix="$" v-if="editedStockMovement.type === 'add'"
                      v-model="editedStockMovement.costPrice" :rules="[
                        v => +v >= 0 || 'El precio de costo no puede ser negativo'
                      ]" label="Costo lote total" type="number" required></v-text-field>

                    <v-text-field v-model="editedStockMovement.reason" label="Motivo"></v-text-field>

                    <v-alert v-if="editedStockMovement.type === 'remove' && editedStockMovement.reason === 'sale'"
                      type="warning" text dense>
                      Este movimiento fue generado por una venta. Editar con precaución.
                    </v-alert>
                  </v-form>
                </v-card-text>
                <v-divider />
                <v-card-actions v-if="!$vuetify.breakpoint.smAndDown">
                  <v-spacer></v-spacer>
                  <v-btn text @click="closeStockMovementEditDialog">Cancelar</v-btn>
                  <v-btn :disabled="!stockMovementEditValid" :loading="loading.stockMovementEdit" color="primary"
                    @click="saveStockMovementEdit">
                    Guardar Cambios
                  </v-btn>
                </v-card-actions>
                <v-card-actions v-if="$vuetify.breakpoint.smAndDown">
                  <v-btn x-large :disabled="!stockMovementEditValid" :loading="loading.stockMovementEdit"
                    color="primary" @click="saveStockMovementEdit" block>
                    Guardar Cambios
                  </v-btn>
                </v-card-actions>
                <v-card-actions v-if="$vuetify.breakpoint.smAndDown">
                  <v-btn large @click="closeStockMovementEditDialog" block>Cancelar</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-tab-item>

        </v-tabs-items>
      </v-card-text>
    </v-card>



    <!-- Add confirmation dialog -->
    <v-dialog v-model="confirmDialog.show" max-width="400">
      <v-card>
        <v-card-title>{{ confirmDialog.title }}</v-card-title>
        <v-card-text>{{ confirmDialog.message }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="confirmDialog.show = false">Cancelar</v-btn>
          <v-btn color="primary" @click="confirmDialog.action">
            Confirmar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-container>
</template>

<script>
import {
  getFirestore,
  collection,
  addDoc,
  getDocs,
  updateDoc,
  deleteDoc,
  getDoc,

  doc,
  serverTimestamp,
  onSnapshot,
  query,
  orderBy,
  where,
} from 'firebase/firestore';
import { getStorage, ref as storageRef, uploadBytes, getDownloadURL } from 'firebase/storage';
import ApexCharts from "vue-apexcharts";
import { ref as vueRef } from 'vue'
import { debounce } from 'lodash';
import { logAuditEvent } from '@/error/audit.js';

const SNACKBAR_DURATION = 3000

export default {
  components: {
    apexchart: ApexCharts,
  },
  name: "StoreManager",
  data() {
    return {
      search: '',
      tab: 0,
      db: getFirestore(),
      storage: getStorage(),
      items: [],
      stockHeaders: [
        { text: "Imagen", value: "image", sortable: false },
        { text: "Nombre", value: "name" },
        { text: "Categoría", value: "category" },
        { text: "Stock", value: "stock" },
        { text: "Disponible", value: "available" },
        { text: "Precio ($)", value: "price" },
        { text: "Acciones", value: "actions", sortable: false },
      ],
      editDialog: false,
      editValid: false,
      editedItem: {
        id: "",
        name: "",
        price: null,
        image: null,
        category: "",
        visibleAtStore: true,
      },
      dialogTitle: "Add New Item",
      transactions: [],
      transactionHeaders: [
        { text: "Fecha", value: "date" },
        { text: "Ingresos ($)", value: "total" },
        { text: "Items", value: "items" },
      ],
      loadingTransactions: true,
      selectedItem: null,
      stockChartData: null,
      earningsChartData: null,
      stockChartOptions: {
        theme: {
          mode: this.$vuetify.theme.dark ? 'dark' : 'light',
        },
        chart: {
          id: 'stock-chart',
          type: 'line',
          toolbar: {
            show: true
          },
          animations: {
            enabled: true
          }
        },
        stroke: {
          curve: 'smooth',
          width: 3
        },
        colors: ['#2196F3'],
        xaxis: {
          type: 'datetime',
          title: {
            text: 'Fecha',
            style: {
              fontSize: '14px',
              fontWeight: 500
            }
          }
        },
        yaxis: {
          title: {
            text: 'Nivel de Stock',
            style: {
              fontSize: '14px',
              fontWeight: 500
            }
          },
          min: 0
        },
        title: {
          text: 'Nivel de Stock en el Tiempo',
          align: 'left',
          style: {
            fontSize: '16px',
            fontWeight: 600
          }
        },
        grid: {
          borderColor: '#f1f1f1'
        },
        tooltip: {
          x: {
            format: 'dd MMM yyyy'
          }
        }
      },
      earningsChartOptions: {
        theme: {
          mode: this.$vuetify.theme.dark ? 'dark' : 'light',
        },
        chart: {
          id: 'earnings-chart',
          type: 'line',
          toolbar: {
            show: true
          },
          animations: {
            enabled: true
          }
        },
        stroke: {
          curve: 'smooth',
          width: 3
        },
        colors: ['#4CAF50'],
        xaxis: {
          type: 'datetime',
          title: {
            text: 'Fecha',
            style: {
              fontSize: '14px',
              fontWeight: 500
            }
          }
        },
        yaxis: {
          title: {
            text: 'Ingresos Acumulados ($)',
            style: {
              fontSize: '14px',
              fontWeight: 500
            }
          },
          min: 0,
          labels: {
            formatter: (value) => `$${value.toFixed(2)}`
          }
        },
        title: {
          text: 'Ingresos en el Tiempo',
          align: 'left',
          style: {
            fontSize: '16px',
            fontWeight: 600
          }
        },
        grid: {
          borderColor: '#f1f1f1'
        },
        tooltip: {
          x: {
            format: 'dd MMM yyyy'
          },
          y: {
            formatter: (value) => `$${value.toFixed(2)}`
          }
        }
      },
      profitHeaders: [
        { text: "Período", value: "period" },
        { text: "Ingresos ($)", value: "revenue" },
        { text: "Costo ($)", value: "cost" },
        { text: "Ganancia ($)", value: "profit" },
        { text: "Margen (%)", value: "margin" },
      ],
      profitData: [],
      dateRange: {
        start: null,
        end: null,
      },
      startMenu: false,
      endMenu: false,
      loading: {
        items: false,
        save: false,
        delete: false,
        checkout: false,
        stock: false,
        charts: false,
        stockMovements: false,
        stockMovementEdit: false,
      },
      snackbar: {
        show: false,
        text: '',
        color: 'success'
      },
      confirmDialog: {
        show: false,
        title: '',
        message: '',
        action: null
      },
      stockDialog: false,
      stockValid: false,
      stockAdjustment: {
        type: 'add',
        quantity: null,
        costPrice: null,
        reason: '',
      },
      chartDataCache: new Map(),
      debouncedFetchChartData: null,
      categories: [
        'Bebidas',
        'Comida',
        'Snacks',
        'Postres',
        'Suplementos',
        'BareRecovery',
        'Merchandising',
        'Insumos de limpieza',
        'Insumos de oficina',
        'Insumos de cocina',
        'Insumos de mantenimiento',
        'Otros'
      ].sort(),
      search2: null,
      stockMovementsHeaders: [
        { text: "Fecha", value: "date" },
        { text: "Tipo", value: "type" },
        { text: "Cantidad", value: "change" },
        { text: "Costo", value: "costPrice" },
        { text: "Motivo", value: "reason" },
        { text: "Acciones", value: "actions", sortable: false },
      ],
      stockMovements: [],
      costsChartData: null,
      costsChartOptions: {
        theme: {
          mode: this.$vuetify.theme.dark ? 'dark' : 'light',
        },
        chart: {
          id: 'costs-chart',
          type: 'line',
          toolbar: {
            show: true
          },
          animations: {
            enabled: true
          }
        },
        stroke: {
          curve: 'smooth',
          width: 3
        },
        colors: ['#FF5252'],
        xaxis: {
          type: 'datetime',
          title: {
            text: 'Fecha',
            style: {
              fontSize: '14px',
              fontWeight: 500
            }
          }
        },
        yaxis: {
          title: {
            text: 'Costos Acumulados ($)',
            style: {
              fontSize: '14px',
              fontWeight: 500
            }
          },
          min: 0,
          labels: {
            formatter: (value) => `$${value.toFixed(2)}`
          }
        },
        title: {
          text: 'Costos Acumulados en el Tiempo',
          align: 'left',
          style: {
            fontSize: '16px',
            fontWeight: 600
          }
        },
        grid: {
          borderColor: '#f1f1f1'
        },
        tooltip: {
          x: {
            format: 'dd MMM yyyy'
          },
          y: {
            formatter: (value) => `$${value.toFixed(2)}`
          }
        }
      },
      stockMovementEditDialog: false,
      stockMovementEditValid: true,
      editedStockMovement: {
        id: "",
        type: "",
        change: 0,
        costPrice: null,
        reason: "",
        docRef: null
      },
    };
  },
  created() {
    this.fetchItems();
    this.debouncedFetchChartData = debounce(this.fetchStockChangeData, 300);
  },
  watch: {
    selectedItem: {
      handler(newItem) {
        if (newItem && this.tab === 2) {
          this.debouncedFetchChartData(newItem.id);
        } else {
          this.stockChartData = null;
          this.earningsChartData = null;
          this.costsChartData = null;
        }
      },
      immediate: true
    },
    'editedItem.category': {
      handler(newCategory) {
        if (newCategory && !this.categories.includes(newCategory)) {
          this.categories.push(newCategory);
          this.categories.sort();
        }
      }
    }
  },
  methods: {
    async fetchStockChangeData(itemId) {
      if (this.chartDataCache.has(itemId)) {
        const cachedData = this.chartDataCache.get(itemId);
        this.stockChartData = cachedData.stockChartData;
        this.earningsChartData = cachedData.earningsChartData;
        this.costsChartData = cachedData.costsChartData;
        this.stockMovements = cachedData.stockMovements;
        return;
      }

      this.loading.charts = true;
      this.loading.stockMovements = true;

      try {
        const stockRef = collection(this.db, 'stock');
        const q = query(
          stockRef,
          where('itemId', '==', itemId),
          orderBy('date', 'asc')
        );
        const querySnapshot = await getDocs(q);

        const stockData = [];
        const earningsData = [];
        const costsData = [];
        const movements = [];
        let cumulativeEarnings = 0;
        let cumulativeCosts = 0;
        let currentStock = 0;

        querySnapshot.docs.forEach((doc) => {
          const data = doc.data();
          const date = data.date.toDate();

          // Update stock data for chart
          currentStock = data.newStock;
          stockData.push({ x: date, y: currentStock });

          // Update earnings data if it's a sale
          if (data.reason === 'sale') {
            const earnings = data.change * -1 * this.selectedItem.price;
            cumulativeEarnings += earnings;
            earningsData.push({ x: date, y: cumulativeEarnings });
          }

          // Update costs data if there's a cost associated
          if (data.costPrice) {
            cumulativeCosts += data.costPrice;
            costsData.push({ x: date, y: cumulativeCosts });
          }

          // Add to movements table (this stays in reverse order for the table display)
          movements.unshift({
            id: doc.id,
            docRef: doc.id,
            date: date,
            type: data.type,
            change: data.change,
            costPrice: data.costPrice,
            reason: data.reason,
            newStock: data.newStock
          });
        });

        const chartData = {
          stockChartData: [{ name: 'Stock', data: stockData }],
          earningsChartData: [{ name: 'Ingresos acumulados', data: earningsData }],
          costsChartData: [{ name: 'Costos acumulados', data: costsData }],
          stockMovements: movements
        };

        this.chartDataCache.set(itemId, chartData);

        this.stockChartData = chartData.stockChartData;
        this.earningsChartData = chartData.earningsChartData;
        this.costsChartData = chartData.costsChartData;
        this.stockMovements = chartData.stockMovements;
      } catch (error) {
        console.error('Error fetching stock change data:', error);
        this.$notify({
          title: 'Error',
          message: 'Error cargando datos del gráfico',
          type: 'error'
        });
      } finally {
        this.loading.charts = false;
        this.loading.stockMovements = false;
      }
    },

    clearChartCache(itemId) {
      this.chartDataCache.delete(itemId);
    },

    openEditDialog(item = null) {
      if (item) {
        this.dialogTitle = "Editar Item";
        this.editedItem = {
          id: item.id,
          name: item.name,
          price: item.price,
          image: null,
          category: item.category || '',
          existingImage: item.image,
          visibleAtStore: item.visibleAtStore ?? true,
        };
      } else {
        this.dialogTitle = "Agregar Nuevo Item";
        this.editedItem = {
          id: "",
          name: "",
          price: null,
          image: null,
          category: "",
          existingImage: "",
          visibleAtStore: true,
        };
      }
      this.editDialog = true;
    },

    closeEditDialog() {
      this.editDialog = false;
      if (this.$refs.editForm) {
        this.$refs.editForm.reset();
      }
      this.editedItem = {
        id: "",
        name: "",
        price: null,
        image: null,
        category: "",
        existingImage: "",
        visibleAtStore: true,
      };
      this.dialogTitle = "Agregar Nuevo Item";
    },

    async saveItem() {
      this.loading.save = true;
      try {
        await this.$refs.editForm.validate();
        let imageURL = this.editedItem.existingImage || "";
        if (this.editedItem.image && this.editedItem.image instanceof File) {
          const imagePath = `items/${Date.now()}_${this.editedItem.image.name}`;
          const imgRef = storageRef(this.storage, imagePath);
          const snapshot = await uploadBytes(imgRef, this.editedItem.image);
          imageURL = await getDownloadURL(snapshot.ref);
        }

        const itemData = {
          name: this.editedItem.name,
          price: parseFloat(this.editedItem.price),
          image: imageURL,
          category: this.editedItem.category,
          visibleAtStore: this.editedItem.visibleAtStore,
          updatedAt: serverTimestamp(),
        };

        if (this.editedItem.id) {
          const itemRef = doc(this.db, "items", this.editedItem.id);
          await updateDoc(itemRef, itemData);
          logAuditEvent('update', this.$store.state.user?.uid,
            `Updated item: ${this.editedItem.name} (${this.editedItem.id})`);
        } else {
          itemData.createdAt = serverTimestamp();
          itemData.deleted = false;
          itemData.stock = 0;
          const docRef = await addDoc(collection(this.db, "items"), itemData);
          logAuditEvent('create', this.$store.state.user?.uid,
            `Created new item: ${this.editedItem.name} (${docRef.id})`);
        }

        this.$notify({
          title: this.editedItem.id ? 'Item actualizado exitosamente' : 'Item creado exitosamente',
          type: 'success'
        });
        this.closeEditDialog();
      } catch (error) {
        logAuditEvent('error', this.$store.state.user?.uid,
          `Error saving item: ${error.message}`);
        this.$notify({
          title: 'Error',
          message: 'Error al guardar item',
          type: 'error'
        });
        console.error("Error saving item:", error);
      } finally {
        this.loading.save = false;
      }
    },

    async fetchItems() {
      this.loading.items = true
      try {
        const itemsCollection = collection(this.db, "items");
        const q = query(itemsCollection, where("deleted", "==", false));
        const querySnapshot = await getDocs(q);
        this.items = [];
        querySnapshot.forEach((doc) => {
          this.items.push({ id: doc.id, ...doc.data() });
        });

        onSnapshot(q, (snapshot) => {
          this.items = [];
          snapshot.forEach((doc) => {
            this.items.push({ id: doc.id, ...doc.data() });
          });
        });
      } catch (error) {
        this.$notify({
          title: 'Error',
          message: 'Error cargando items',
          type: 'error'
        });
        console.error("Error fetching items:", error);
      } finally {
        this.loading.items = false
      }
    },

    async deleteItem(id) {
      this.showConfirmDialog(
        'Eliminar Item',
        '¿Está seguro que desea eliminar este item? Esta acción no se puede deshacer.',
        async () => {
          this.loading.delete = true
          try {
            const itemRef = doc(this.db, "items", id);
            const itemDoc = await getDoc(itemRef);
            const itemName = itemDoc.data().name;

            await updateDoc(itemRef, { deleted: true });
            logAuditEvent('delete', this.$store.state.user?.uid,
              `Deleted item: ${itemName} (${id})`);

            this.$notify({
              title: 'Item eliminado exitosamente',
              type: 'success'
            });
          } catch (error) {
            logAuditEvent('error', this.$store.state.user?.uid,
              `Error deleting item: ${error.message}`);
            this.$notify({
              title: 'Error',
              message: 'Error al eliminar item',
              type: 'error'
            });
            console.error("Error deleting item:", error);
          } finally {
            this.loading.delete = false
            this.confirmDialog.show = false
          }
        }
      )
    },

    listenToTransactions() {
      const transactionsQuery = query(
        collection(this.db, "transactions"),
        orderBy("date", "desc")
      );
      onSnapshot(transactionsQuery, (snapshot) => {
        this.transactions = [];
        snapshot.forEach((doc) => {
          this.transactions.push({ id: doc.id, ...doc.data() });
        });
        this.loadingTransactions = false;
      });
    },

    formatDate(timestamp) {
      if (!timestamp) return "";
      const date = timestamp.toDate();
      return date.toLocaleString();
    },

    async fetchProfitData() {
      try {
        if (!this.dateRange.start || !this.dateRange.end) {
          alert('Por favor seleccione fecha inicial y final');
          return;
        }

        const start = new Date(this.dateRange.start);
        const end = new Date(this.dateRange.end);
        end.setHours(23, 59, 59);

        const transactionsRef = collection(this.db, "transactions");
        const q = query(
          transactionsRef,
          where("date", ">=", start),
          where("date", "<=", end),
          orderBy("date", "asc")
        );

        const querySnapshot = await getDocs(q);

        let totalRevenue = 0;
        let totalCost = 0;
        let transactions = 0;

        querySnapshot.forEach((doc) => {
          const data = doc.data();
          if (data.totalRevenue !== undefined && data.totalCost !== undefined) {
            totalRevenue += parseFloat(data.totalRevenue) || 0;
            totalCost += parseFloat(data.totalCost) || 0;
          } else {
            totalRevenue += parseFloat(data.total) || 0;
            if (data.items && Array.isArray(data.items)) {
              data.items.forEach(item => {
                const itemCost = (parseFloat(item.costPrice) || 0) * (parseInt(item.quantity) || 0);
                totalCost += itemCost;
              });
            }
          }
          transactions++;
        });

        const profit = totalRevenue - totalCost;
        const margin = totalRevenue > 0 ? (profit / totalRevenue) * 100 : 0;

        this.profitData = [{
          period: `${this.dateRange.start} al ${this.dateRange.end}`,
          revenue: totalRevenue.toFixed(2),
          cost: totalCost.toFixed(2),
          profit: profit.toFixed(2),
          margin: margin,
          transactions: transactions
        }];

        console.log('Resumen P&L:', {
          totalRevenue,
          totalCost,
          profit,
          margin,
          transactions
        });

      } catch (error) {
        console.error("Error al generar reporte:", error);
        alert("Error al generar reporte");
      }
    },

    showConfirmDialog(title, message, action) {
      this.confirmDialog.title = title
      this.confirmDialog.message = message
      this.confirmDialog.action = action
      this.confirmDialog.show = true
    },

    openStockDialog(item) {
      this.selectedItem = item;
      this.stockDialog = true;
      this.stockAdjustment = {
        type: 'add',
        quantity: null,
        costPrice: item.costPrice,
        reason: '',
      };
    },

    closeStockDialog() {
      this.stockDialog = false;
      this.selectedItem = null;
      if (this.$refs.stockForm) {
        this.$refs.stockForm.reset();
      }
      this.stockAdjustment = {
        type: 'add',
        quantity: null,
        costPrice: null,
        reason: '',
      };
    },

    async saveStockAdjustment() {
      this.loading.stock = true;
      try {
        await this.$refs.stockForm.validate();

        const itemRef = doc(this.db, "items", this.selectedItem.id);
        const itemDoc = await getDoc(itemRef);
        const currentStock = itemDoc.data().stock || 0;

        const quantity = parseInt(this.stockAdjustment.quantity);
        const newStock = this.stockAdjustment.type === 'add'
          ? currentStock + quantity
          : currentStock - quantity;

        if (newStock < 0) {
          throw new Error('No se puede reducir el stock por debajo de 0');
        }

        // Update item stock
        await updateDoc(itemRef, {
          stock: newStock,
          updatedAt: serverTimestamp(),
        });

        // Add stock movement record
        await addDoc(collection(this.db, "stock"), {
          itemId: this.selectedItem.id,
          date: serverTimestamp(),
          change: this.stockAdjustment.type === 'add' ? quantity : -quantity,
          reason: this.stockAdjustment.reason,
          newStock: newStock,
          costPrice: this.stockAdjustment.type === 'add' ? parseFloat(this.stockAdjustment.costPrice) : null,
          type: this.stockAdjustment.type,
        });

        logAuditEvent('update', this.$store.state.user?.uid,
          `Stock adjustment for ${this.selectedItem.name} (${this.selectedItem.id}): ${this.stockAdjustment.type === 'add' ? '+' : '-'}${quantity} units. New stock: ${newStock}`);

        this.$notify({
          title: 'Stock actualizado exitosamente',
          type: 'success'
        });
        this.clearChartCache(this.selectedItem.id);
        this.closeStockDialog();
      } catch (error) {
        logAuditEvent('error', this.$store.state.user?.uid,
          `Error adjusting stock: ${error.message}`);
        this.$notify({
          title: 'Error',
          message: error.message || 'Error al actualizar stock',
          type: 'error'
        });
        console.error("Error al actualizar stock:", error);
      } finally {
        this.loading.stock = false;
      }
    },

    openStockMovementEditDialog(item) {
      this.editedStockMovement = {
        id: item.id,
        type: item.type,
        change: item.change,
        costPrice: item.costPrice,
        reason: item.reason,
        docRef: item.docRef
      };
      this.stockMovementEditDialog = true;
    },

    closeStockMovementEditDialog() {
      this.stockMovementEditDialog = false;
      if (this.$refs.stockMovementEditForm) {
        this.$refs.stockMovementEditForm.reset();
      }
      this.editedStockMovement = {
        id: "",
        type: "",
        change: 0,
        costPrice: null,
        reason: "",
        docRef: null
      };
    },

    async saveStockMovementEdit() {
      this.loading.stockMovementEdit = true;
      try {
        if (!this.editedStockMovement.docRef) {
          throw new Error('No se pudo encontrar la referencia al documento');
        }

        // Get document reference from Firestore
        const stockMovementRef = doc(this.db, "stock", this.editedStockMovement.docRef);

        // Update only the allowed fields
        const updateData = {
          costPrice: this.editedStockMovement.type === 'add' ? parseFloat(this.editedStockMovement.costPrice) : null,
          reason: this.editedStockMovement.reason
        };

        await updateDoc(stockMovementRef, updateData);

        logAuditEvent('update', this.$store.state.user?.uid,
          `Edited stock movement for item: ${this.selectedItem.name} (${this.selectedItem.id})`);

        // Clear cache to reload data
        this.clearChartCache(this.selectedItem.id);

        // Refetch chart data
        this.debouncedFetchChartData(this.selectedItem.id);

        this.$notify({
          title: 'Movimiento de stock actualizado exitosamente',
          type: 'success'
        });

        this.closeStockMovementEditDialog();
      } catch (error) {
        logAuditEvent('error', this.$store.state.user?.uid,
          `Error updating stock movement: ${error.message}`);
        this.$notify({
          title: 'Error',
          message: error.message || 'Error al actualizar movimiento de stock',
          type: 'error'
        });
        console.error("Error al actualizar movimiento de stock:", error);
      } finally {
        this.loading.stockMovementEdit = false;
      }
    },
  },
  computed: {
    storeItems() {
      return this.items.filter(item => item.visibleAtStore);
    },
    inventoryItems() {
      return this.items.filter(item => !item.visibleAtStore);
    }
  },
};
</script>

<style scoped>
.v-data-table img {
  max-width: 100px;
}
</style>
